import makeAsyncScriptLoader from "react-async-script"
import Paypal from "./paypal"


const globalName = "paypal"
const URL = `https://www.paypal.com/sdk/js?client-id=${process.env.GATSBY_PAYPAL_CL}&currency=MXN`

export default makeAsyncScriptLoader(URL, {
    globalName: globalName,
    removeOnUnmount: true
})(Paypal);
