import React from 'react';
import { connect } from 'react-redux'
import { changePD } from '../state/store'

class PaypalMXButtons extends React.Component {
    componentDidUpdate(prevProps) {
        console.log('paypal loaded, creating buttons')
        const { amt, dispatch, lang } = this.props
        this.props.paypal.Buttons({
            createOrder: function (data, actions) {
                console.log('creating order for', amt)
                return actions.order.create({
                    purchase_units: [{
                        amount: {
                            value: amt
                        }
                    }]
                })
            },
            onApprove: function (data, actions) {
                return actions.order.capture().then(function (details) {
                    if (details.status === 'COMPLETED') {
                        dispatch(changePD('yes'))
                    }
                    const pkg = {
                        lang: lang,
                        order_id: details.id,
                        amount: details.purchase_units[0].amount.value,
                        status: details.status,
                        payer_id: details.payer.payer_id,
                        first_name: details.payer.name.given_name,
                        last_name: details.payer.name.surname,
                        email: details.payer.email_address
                    }
                    const url = 'https://gentle-chamber-75846.herokuapp.com/site/registry'
                    return fetch(url, {
                        method: 'post',
                        mode: "cors",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(pkg)
                    }).then((res) => {
                        console.log('recorded transaction')
                    })
                });
            }
        }).render(this._container)
    }
    render() {
        return (
            <div ref={(r) => this._container = r} />)
    }
}

export default connect(state => ({
    lang: state.lang,
    amt: state.amt,
    paid: state.paid
}), null)(PaypalMXButtons)
