import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from 'reactstrap'
import { connect } from 'react-redux'
import styles from '../styles/layout.module.css'
import CurWrapper from '../components/currency-wrapper'
import { Button, Form, Input } from 'reactstrap'
import { changeAMT } from "../state/store"
import Refresh from '../components/refresh'
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'

class Registry extends React.Component {


  handleSubmit = e => {
    e.preventDefault()
    this.props.dispatch(changeAMT(e.target.amount.value.toString()))
  }


  render() {

    const { amt, lang, paid } = this.props

    return (
      <Layout>
        <SEO title="Registry" />
        <Container className='mt-4'>
          <Row>
            <Col className={styles.main}>
              <img style={{ border: "10px solid black", display: 'block', margin: 'auto' }} src='https://lh3.googleusercontent.com/wTf0Wi-NHDMvcs-Ip4X_71HQBCgy7NDmuKrEc-LavZpnkm-8CTDbfR1TFQUUIdJDqKdZ358N7KVY7gndp7X24WaZ2VFqzGo0uzLu9T_naEFudxJPyyFxGzb-ye6gsCwRdNVyePW8qPM=d' alt='Santorini, Greece' />
            </Col>
          </Row>
          <Row className='mt-5'>
            <Col className={styles.main}>
              <div>
                <h1>{lang === 'english' ? 'Registry' : 'Registro de Bodas'}</h1>
                <p style={{ marginTop: '40px' }}>{lang === 'english' ? <span>We love to travel! There are too many beautiful places around the world.  We want to travel to at least one new place every year. We're saving money for our next big trip: Greece.
                It has always been our dream to travel to Greece together. If you are able to help us with any amount, we are 100 percent grateful for your contribution. You can make a contribution online or leave cash in our box at the wedding.</span> :
                  <span>Nos encanta viajar! Hay demasiados lugares hermosos alrededor del mundo. Queremos viajar al menos a un nuevo lugar cada año. Estamos ahorrando dinero para nuestro próximo gran viaje: Grecia.
                  Siempre ha sido nuestro sueño viajar juntos a Grecia. Si puede ayudarnos con cualquier cantidad, estamos 100 por ciento agradecidos por su contribución. Puede hacer una contribución en línea o dejar dinero en efectivo en nuestra buzón que encontrarán en la boda.</span>}</p>
              </div>
              {paid === 'no' ?
                (<div>
                  {amt !== 'none' ?
                    (<div>
                      <p>{lang === 'english' ? 'Choose an option below to contribute' : 'Elija una de las siguientes opciones para contribuir'} ${amt} {lang === 'english' ? 'USD' : 'MXN'}!</p>
                      <CurWrapper />
                    </div>) :
                    (<div>
                      <p>{lang === 'english' ? 'How much would you like to contribute?' : 'Cuánto te gustaría contribuir?'}</p>
                      <Form onSubmit={(e) => this.handleSubmit(e)} inline>
                        <InputGroup >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>$</InputGroupText>
                          </InputGroupAddon>
                          <Input type="number" name="amount" min={lang === 'english' ? '10' : '200'} step={lang === 'english' ? '10' : '200'} required />
                          <InputGroupAddon addonType="append">
                            <InputGroupText>{lang === 'english' ? 'USD' : 'MXN'}</InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                        <Button style={{ marginLeft: '10px' }} type='submit'>{lang === 'english' ? 'Pay' : 'Pagar'}</Button>
                      </Form>
                    </div>)}
                </div>) :
                (<p>{lang === 'english' ? 'Thank you for your contribution! You will receive a receipt from Paypal via email.' : 'Gracias por contribuir! Recibirá un recibo de Paypal por correo electrónico.'}</p>)}
              {amt === 'refresh' ? <Refresh /> : <div></div>}
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default connect(state => ({
  amt: state.amt,
  lang: state.lang,
  paid: state.paid
}), null)(Registry)
