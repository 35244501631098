import React from 'react';
import PaypalMX from './paypal-wrapper-mxn'
import PaypalUS from './paypal-wrapper-usd'
import { connect } from 'react-redux'


const CurWrapper = ({ lang }) => <div>{lang === 'english' ? <PaypalUS /> : <PaypalMX />}</div>

export default connect(state => ({
    lang: state.lang,
    amt: state.amt
}), null)(CurWrapper)