import React, { Component } from 'react'
import { connect } from 'react-redux'

class Refresh extends Component {
    componentDidMount() {
        window.location.reload()
    }
    render() {
        const { lang } = this.props
        return (
            <div>{alert(lang === 'english' ? 'Must refresh to generate new payment options. Click OK.' : 'Debe actualizarse para generar nuevas opciones de pago. Haga clic OK.')}</div>)
    }
}

export default connect(state => ({
    amt: state.amt,
    lang: state.lang
}), null)(Refresh)
